import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AlbumThumbnail from '../components/album_thumbnail';
import { fetchAlbums } from '../actions/album_actions';

class AlbumsLayout extends React.Component {
  componentDidMount() {
    this.props.fetchAlbums();
  }

  renderLoadingState() {
    return (
      <article>
        <header>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Albums
              </li>
            </ol>
          </nav>
        </header>
        <section>
          <div className="fa-3x">
            <i className="fa fa-spinner fa-pulse"></i>
          </div>
        </section>
      </article>
    );
  }

  render() {
    if (this.props.loading) {
      return this.renderLoadingState();
    }

    return (
      <article>
        <header>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Albums
              </li>
            </ol>
          </nav>
        </header>
        <section>
          <div className="row">
            {this.props.albums.map((album) => {
              return <AlbumThumbnail key={album.link} link={album.link} />;
            })}
          </div>
        </section>
      </article>
    );
  }
}

AlbumsLayout.propTypes = {
  fetchAlbums: PropTypes.func.isRequired,
  albums: PropTypes.array.isRequired,
  loading: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    albums: state.albums.records,
    loading: state.albums.loading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAlbums: () => dispatch(fetchAlbums())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlbumsLayout);
