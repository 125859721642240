const environment = process.env.NODE_ENV || 'development';
const config = {
  development: {
    FILE_API_ROOT_URL: 'https://icwpxieh7d.execute-api.us-west-2.amazonaws.com/dev',
    THUMB_API_ROOT_URL: 'https://j1vaq4h4yi.execute-api.us-west-2.amazonaws.com/Prod'
  },
  production: {
    FILE_API_ROOT_URL: 'https://icwpxieh7d.execute-api.us-west-2.amazonaws.com/dev',
    THUMB_API_ROOT_URL: 'https://j1vaq4h4yi.execute-api.us-west-2.amazonaws.com/Prod'
  },
  test: {
    FILE_API_ROOT_URL: 'https://r2z8to9fsc.execute-api.us-west-2.amazonaws.com/dev',
    THUMB_API_ROOT_URL: 'https://j1vaq4h4yi.execute-api.us-west-2.amazonaws.com/Prod'
  },
};
export default config[environment];
