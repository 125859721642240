import React from 'react';
import { Link } from 'react-router-dom';


class ErrorLayout extends React.Component {
  render() {
    return (
      <article className="jumbotron">
        <h1 className="display-4">Error 404</h1>
        <p className="lead">Nothing to see here. Get back to the fun</p>
        <p className="lead"><Link to="/">Albums</Link></p>
      </article>
    );
  }
}

export default ErrorLayout;
