import React from 'react';
import PropTypes from 'prop-types';

class AppFooter extends React.Component {
  render() {
    return (
      <footer className="page-footer font-small text-dark bg-light pt-3">
        <div className="container text-center text-md-left">
          <div className="row">
            <h4 className="col-md-12 mx-auto">{this.props.title}</h4>
            <hr className="clearfix w-100 d-md-none" />
            <div className="col-md-4 mx-auto text-muted">
              <ul className="list-unstyled">
                <li>{this.props.title}</li>
                <li>
                  <a href="mailto:your-email@domain.com">your-email@domain.com</a>
                </li>
              </ul>
            </div>
            <hr className="clearfix w-100 d-md-none" />
            <div className="col-md-4 mx-auto text-muted">
              <ul className="list-unstyled">
                <li>
                  <i className="fa fa-twitter"></i>
                  &nbsp;
                  <a href="https://twitter.com/mattfite" target="_blank" rel="noopener noreferrer">
                    mattfite
                  </a>
                </li>
              </ul>
            </div>
            <hr className="clearfix w-100 d-md-none" />
            <div className="col-md-4 mx-auto text-muted">
              <p>blogging about things that interest me. Photography, family and AWS.</p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

AppFooter.propTypes = {
  title: PropTypes.string.isRequired
};

export default AppFooter;
