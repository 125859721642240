import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

const styles = {
  brand: {
    fontSize: 26,
    fontWeight: 300,
    letterSpacing: -1
  }
};

class AppHeader extends React.Component {
  isActivePath(...paths) {
    const WILDCARD = '*';
    const { pathname } = this.props.location;

    let path;
    for (let i = 0; i < paths.length; i++) {
      path = paths[i];

      if (path.startsWith(WILDCARD) && pathname.endsWith(path.substr(1))) {
        return true;
      } else if (path.endsWith(WILDCARD) && pathname.startsWith(path.substr(0, path.length - 1))) {
        return true;
      } else if (pathname === path) {
        return true;
      }
    }

    return false;
  }

  render() {
    return (
      <header>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark navbar-fixed-top mb-3">
          <div className="container">
            <Link to="/" className="navbar-brand" style={styles.brand}>{this.props.title}</Link>
            <div className="collapse navbar-collapse">
              <ul className="navbar-nav mr-auto"></ul>
              <ul className="navbar-nav">
                <li className={`nav-item ${(this.isActivePath('/about*') ? 'active' : '')}`}>
                  <Link to="/about" className="nav-link">About</Link>
                </li>
                <li className={`nav-item ${(this.isActivePath('/', '/album*') ? 'active' : '')}`}>
                  <Link to="/" className="nav-link">Photos</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

AppHeader.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(AppHeader);
