import { combineReducers } from 'redux';
import albumReducer from './album_reducer';
import photoReducer from './photo_reducer';
import imageViewerReducer from './image_viewer_reducer';

export default combineReducers({
  albums: albumReducer,
  photos: photoReducer,
  imageViewer: imageViewerReducer
});
