import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import AppHeader from '../components/app_header';
import AppFooter from '../components/app_footer';

import AlbumsLayout from './albums_layout';
import PhotosLayout from './photos_layout';
import AboutLayout from './about_layout';
import ErrorLayout from './error_layout';

class AppLayout extends React.Component {
  render() {
    return (
      <div className="page-wrapper">
        <AppHeader title={this.props.title} />
        <section className="container">
          <Switch>
            <Route exact path="/" component={AlbumsLayout} />
            <Route path="/album/:albumName" component={PhotosLayout} />
            <Route path="/about" component={AboutLayout} />
            <Route component={ErrorLayout} />
          </Switch>
        </section>
        <AppFooter title={this.props.title} />
      </div>
    );
  }
}

AppLayout.propTypes = {
  title: PropTypes.string.isRequired
};

AppLayout.defaultProps = {
  title: 'mattfite\'s blog'
};

export default withRouter(AppLayout);
