import React from 'react';

class AboutLayout extends React.Component {
  render() {
    return (
      <article className="jumbotron">
        <h1 className="display-4">About</h1>
        <p className="lead">This is the base Jekyll theme. You can find out more info about customizing your Jekyll theme, as well as basic Jekyll usage documentation at <a href="http://jekyllrb.com/" target="_blank" rel="noopener noreferrer">jekyllrb.com</a></p>
        <p className="lead">You can find the source code for the Jekyll new theme at: / <a href="https://github.com/jekyll/minima" target="_blank" rel="noopener noreferrer">minima</a></p>
        <p className="lead">You can find the source code for Jekyll at / <a href="https://github.com/jekyll/jekyll" target="_blank" rel="noopener noreferrer">jekyll</a></p>
      </article>
    );
  }
}

export default AboutLayout;
