import {
  VIEW_IMAGE,
  CLOSE_IMAGE_VIEWER
} from '../actions/image_viewer_actions';

const initialState = {
  show: false,
  albumName: null,
  photoIndex: -1
};

export default function(state = initialState, action) {
  switch (action.type) {
    case VIEW_IMAGE:
      return Object.assign({}, state, {
        show: true,
        albumName: action.albumName,
        photoIndex: action.photoIndex
      });
    case CLOSE_IMAGE_VIEWER:
      return Object.assign({}, state, {
        show: false,
        albumName: null,
        photoIndex: -1
      });
    default:
      return state;
  }
}
