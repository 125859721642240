import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { viewImage, closeImageViewer } from '../actions/image_viewer_actions';

const KEY = {
  ESCAPE: 27,
  LEFT: 37,
  RIGHT: 39
};

const styles = {
  modal: {
    textAlign: 'center'
  },
  modalDialog: {
    display: 'inline-block',
    width: 'auto',
    maxWidth: 'unset'
  },
  modalBody: {
    padding: 0
  },
  modalBodyImage: {
    maxHeight: '100vh',
    margin: '-113px 0',
    padding: '113px 0',
    zIndex: -1
  },
  modalButton: {
    zIndex: 1
  }
};

class ImageViewer extends React.Component {
  constructor(props) {
    super(props);

    this.modalRef = React.createRef();
  }

  currentImage() {
    return this.props.images[this.props.index];
  }

  hasPreviousImage() {
    return this.props.index - 1 >= 0;
  }

  goToPreviousImage() {
    const prevIndex = this.props.index - 1;
    this.props.viewImage(this.props.albumName, prevIndex);
  }

  hasNextImage() {
    return this.props.index + 1 < this.props.images.length;
  }

  goToNextImage() {
    const nextIndex = this.props.index + 1;
    this.props.viewImage(this.props.albumName, nextIndex);
  }

  // focus trapping & keyboard events

  componentDidUpdate() {
    const modalElement = this.modalRef.current;
    if (this.props.show) {
      modalElement.focus();
    }
  }

  onBlur(ev) {
    const modalElement = this.modalRef.current;
    const elementReceivingFocus = ev.relatedTarget;

    if (!modalElement.contains(elementReceivingFocus)) {
      ev.stopPropagation();
      modalElement.focus();
    }
  }

  onKeyDown(ev) {
    switch (ev.keyCode) {
      case KEY.ESCAPE:
        this.props.onHide();
        break;
      case KEY.LEFT:
        this.hasPreviousImage() && this.goToPreviousImage();
        break;
      case KEY.RIGHT:
        this.hasNextImage() && this.goToNextImage();
        break;
      default:
        // no default
        break;
    }
  }

  render() {
    const display = this.props.show ? 'block' : 'none';
    const fadeDirection = this.props.show ? 'fade in' : 'fade';
    const showOrHide = this.props.show ? 'show' : 'hide';
    const tabIndex = this.props.show ? 0 : -1;
    const currentImage = this.currentImage();

    return (
      <article>
        <div
          className={`modal ${fadeDirection} ${showOrHide}`}
          role="dialog"
          style={Object.assign({}, styles.modal, { display })}
          ref={this.modalRef}
          tabIndex={tabIndex}
          onBlur={(ev) => this.onBlur(ev)}
          onKeyDown={(ev) => this.onKeyDown(ev)}
        >
          <div className="modal-dialog modal-lg" role="document" style={styles.modalDialog}>
            <div className="modal-content bg-dark">
              <div className="modal-header">
                <button className="btn btn-dark ml-auto" style={styles.modalButton} onClick={this.props.onHide}>
                  <i className="fa fa-close"></i>
                </button>
              </div>
              <div className="modal-body" style={styles.modalBody}>
                {currentImage && <img src={currentImage.link} alt="full" style={styles.modalBodyImage} />}
              </div>
              <div className="modal-footer">
                {this.hasPreviousImage() && (
                  <button
                    className="btn btn-dark mr-auto"
                    style={styles.modalButton}
                    onClick={() => this.goToPreviousImage()}
                  >
                    <i className="fa fa-chevron-left"></i>
                  </button>
                )}
                {this.hasNextImage() && (
                  <button className="btn btn-dark" style={styles.modalButton} onClick={() => this.goToNextImage()}>
                    <i className="fa fa-chevron-right"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={`modal-backdrop ${fadeDirection} ${showOrHide}`} style={{ display }}></div>
      </article>
    );
  }
}

ImageViewer.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  viewImage: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  albumName: PropTypes.string
};

ImageViewer.defaultProps = {
  show: false,
  onHide: () => {
    return;
  },
  images: []
};

function mapStateToProps(state) {
  const { albumName } = state.imageViewer;
  const photos = state.photos.byAlbum[albumName] && state.photos.byAlbum[albumName].records;

  return {
    show: state.imageViewer.show,
    images: photos,
    index: state.imageViewer.photoIndex,
    albumName: state.imageViewer.albumName
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onHide: () => dispatch(closeImageViewer()),
    viewImage: (albumName, photoIndex) => dispatch(viewImage(albumName, photoIndex))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageViewer);
