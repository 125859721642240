import config from '../config';

export const FETCH_PHOTOS_STARTED = 'FETCH_PHOTOS_STARTED';
export const FETCH_PHOTOS_COMPLETED = 'FETCH_PHOTOS_COMPLETED';
export const FETCH_PHOTOS_FAILED = 'FETCH_PHOTOS_FAILED';

export function fetchPhotos(albumName) {
  return (dispatch) => {
    dispatch({
      type: FETCH_PHOTOS_STARTED,
      albumName
    });

    const fileExtFilter = encodeURIComponent(['.jpg', '.jpeg', '.png', '.gif'].join('|'));

    fetch(`${config.FILE_API_ROOT_URL}/ls/${encodeURIComponent(albumName)}?type=file&ext=${fileExtFilter}`)
      .then((response) => response.json())
      .then((json) => {
        dispatch({
          type: FETCH_PHOTOS_COMPLETED,
          albumName,
          photos: json
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_PHOTOS_FAILED,
          albumName,
          error: error.message
        });
      });
  };
}
