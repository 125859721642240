import React from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import './styles/grid.css';
import './styles/sticky_footer.scss';

import configureStore from './store/configure_store';
import AppLayout from './layouts/app_layout';

const store = configureStore();

ReactDom.render(
  <Provider store={store}>
    <BrowserRouter>
      <AppLayout />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
