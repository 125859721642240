import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import ImageViewer from '../components/image_viewer';
import ImageGallery from '../components/image_gallery';
import { fetchPhotos } from '../actions/photo_actions';

class PhotosLayout extends React.Component {
  componentDidMount() {
    this.props.fetchPhotos();
  }

  renderEmptyState() {
    return (
      <article>
        <header>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Albums</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {this.props.albumName}
              </li>
            </ol>
          </nav>
        </header>
        <section>
          <h2>no photos</h2>
        </section>
      </article>
    );
  }

  renderLoadingState() {
    return (
      <article>
        <header>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Albums</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {this.props.albumName}
              </li>
            </ol>
          </nav>
        </header>
        <section>
          <div className="fa-3x">
            <i className="fa fa-spinner fa-pulse"></i>
          </div>
        </section>
      </article>
    );
  }

  render() {
    if (this.props.loading) {
      return this.renderLoadingState();
    }

    if (this.props.collection.length === 0) {
      return this.renderEmptyState();
    }

    return (
      <article>
        <header>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Albums</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {this.props.albumName}
              </li>
            </ol>
          </nav>
        </header>
        <section>
          <ImageGallery albumName={this.props.albumName} />
          <ImageViewer />
        </section>
      </article>
    );
  }
}

PhotosLayout.propTypes = {
  albumName: PropTypes.string.isRequired,
  fetchPhotos: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  collection: PropTypes.array
};

function albumNameFromProps(props) {
  return decodeURIComponent(props.match.params.albumName);
}

function mapStateToProps(state, ownProps) {
  const albumName = albumNameFromProps(ownProps);
  // return loading = true or the value that's in the state
  let loading = true;
  if (state.photos.byAlbum.hasOwnProperty(albumName) && typeof state.photos.byAlbum[albumName].loading != 'undefined') {
    loading = state.photos.byAlbum[albumName].loading;
  }

  let collection = [];
  if (state.photos.byAlbum.hasOwnProperty(albumName) && typeof state.photos.byAlbum[albumName].records != 'undefined') {
    collection = state.photos.byAlbum[albumName].records;
  }

  return {
    albumName,
    loading,
    collection
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    fetchPhotos: () => dispatch(fetchPhotos(albumNameFromProps(ownProps)))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotosLayout);
