export const VIEW_IMAGE = 'VIEW_IMAGE';
export const CLOSE_IMAGE_VIEWER = 'CLOSE_IMAGE_VIEWER';

export function viewImage(albumName, photoIndex) {
  return {
    type: VIEW_IMAGE,
    albumName,
    photoIndex
  };
}

export function closeImageViewer() {
  return {
    type: CLOSE_IMAGE_VIEWER
  };
}
