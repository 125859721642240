import React from 'react';
import PropTypes from 'prop-types';
import config from '../config';

const THUMBNAIL_HEIGHT = 400;

class ImageThumbnail extends React.Component {
  thumbnailUrl() {
    const { filePath, fileBasename } = this.props;
    return `${config.THUMB_API_ROOT_URL}${filePath}/${fileBasename}?height=${THUMBNAIL_HEIGHT}`;
  }

  render() {
    return (
      <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 my-auto">
        <img
          width="100%"
          src={this.thumbnailUrl()}
          alt="thumbnail"
          className="rounded mb-3"
          onClick={this.props.onClick}
          style={{ cursor: 'pointer' }}
        />
      </div>
    );
  }
}

ImageThumbnail.propTypes = {
  filePath: PropTypes.string.isRequired,
  fileBasename: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ImageThumbnail;
