import config from '../config';

export const FETCH_ALBUMS_STARTED = 'FETCH_ALBUMS_STARTED';
export const FETCH_ALBUMS_COMPLETED = 'FETCH_ALBUMS_COMPLETED';
export const FETCH_ALBUMS_FAILED = 'FETCH_ALBUMS_FAILED';

export function fetchAlbums() {
  return (dispatch) => {
    dispatch({
      type: FETCH_ALBUMS_STARTED
    });

    fetch(`${config.FILE_API_ROOT_URL}/ls?type=dir`)
      .then((response) => response.json())
      .then((json) => {
        dispatch({
          type: FETCH_ALBUMS_COMPLETED,
          albums: json
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ALBUMS_FAILED,
          error: error.message
        });
      });
  };
}
