import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class AlbumThumbnail extends React.Component {
  albumName() {
    const linkParts = this.props.link.split('/');
    return decodeURIComponent(linkParts[linkParts.length - 2]);
  }

  render() {
    const albumLink = `/album/${encodeURIComponent(this.albumName())}`;

    return (
      <div
        className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <Link
          to={albumLink}
          className="list-group-item list-group-item-action text-center bg-light mb-3">
          <h5 className="mb-1">{this.albumName()}</h5>
          <p className="mb-1">
            <i className="fa fa-folder fa-3x"></i>
          </p>
        </Link>
      </div>
    );
  }
}

AlbumThumbnail.propTypes = {
  link: PropTypes.string.isRequired
};

export default AlbumThumbnail;
