import {
  FETCH_PHOTOS_STARTED,
  FETCH_PHOTOS_COMPLETED,
  FETCH_PHOTOS_FAILED
} from '../actions/photo_actions';

const initialState = {
  byAlbum: {
    // [albumName]: {
    //   records: {},
    //   error: 'message',
    //   loading: false
    // }
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_PHOTOS_STARTED:
      return Object.assign({}, state, {
        byAlbum: Object.assign({}, state.byAlbum, {
          [action.albumName]: {
            records: [],
            error: null,
            loading: true
          }
        })
      });
    case FETCH_PHOTOS_COMPLETED:
      return Object.assign({}, state, {
        byAlbum: Object.assign({}, state.byAlbum, {
          [action.albumName]: {
            records: action.photos,
            error: null,
            loading: false
          }
        })
      });
    case FETCH_PHOTOS_FAILED:
      return Object.assign({}, state, {
        byAlbum: Object.assign({}, state.byAlbum, {
          [action.albumName]: {
            records: [],
            error: action.error,
            loading: false
          }
        })
      });
    default:
      return state;
  }
}
