import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ImageThumbnail from './image_thumbnail';
import { viewImage } from '../actions/image_viewer_actions';

class ImageGallery extends React.Component {
  render() {
    return (
      <div className="row">
        {this.props.photos.map((photo, index) => {
          return (
            <ImageThumbnail
              filePath={photo.filePath}
              fileBasename={photo.fileBasename}
              key={photo.key}
              thumb={photo.link}
              onClick={() => this.props.viewImage(index)} />
          );
        })}
      </div>
    );
  }
}

ImageGallery.propTypes = {
  albumName: PropTypes.string.isRequired,
  photos: PropTypes.array.isRequired,
  viewImage: PropTypes.func.isRequired
};

ImageGallery.defaultProps = {
  photos: []
};

function mapStateToProps(state, ownProps) {
  const { albumName } = ownProps;
  const photos = state.photos.byAlbum[albumName] && state.photos.byAlbum[albumName].records;

  return {
    photos: photos
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    viewImage: index => dispatch(viewImage(ownProps.albumName, index))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageGallery);
