import {
  FETCH_ALBUMS_STARTED,
  FETCH_ALBUMS_COMPLETED,
  FETCH_ALBUMS_FAILED,
} from '../actions/album_actions';

const initialState = {
  records: [],
  error: null,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALBUMS_STARTED:
      return Object.assign({}, state, {
        records: [],
        error: null,
        loading: true
      });
    case FETCH_ALBUMS_COMPLETED:
      return Object.assign({}, state, {
        records: action.albums,
        error: null,
        loading: false
      });
    case FETCH_ALBUMS_FAILED:
      return Object.assign({}, state, {
        records: [],
        error: action.error,
        loading: false
      });
    default:
      return state;
  }
}
